import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import ACFButton from '../components/ACFButton'
import GlobalContext from '../components/GlobalContext'

const Page = ({
  data: {
    wordpressPage: {
      title,
      content,
      acf: { image, list, button },
    },
  },
}) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })
  const { isLoaded } = useContext(GlobalContext)

  let timeout = 0

  return (
    <section
      ref={inViewRef}
      className={cs(
        'flex flex-wrap md:flex-no-wrap items-stretch w-full min-h-screen header-padding-offset',
        { 'in-view': inView && isLoaded }
      )}
    >
      <div className="flex-auto flex-col justify-center">
        <div className="primary-text-section max-w-3xl mx-auto">
          <h1
            className="primary-text-section__dark-title primary-appearance"
            style={{ transitionDelay: `${(timeout += 150)}ms` }}
          >
            {title}
          </h1>
          <div
            className="primary-description primary-appearance mt-6"
            style={{ transitionDelay: `${(timeout += 150)}ms` }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {list && list.length ? (
            <div className="my-10 lg:my-12">
              <ul className="accent-list accent-list--large-gutter">
                {list.map(({ text }, i) => (
                  <li key={i}>
                    <div
                      className="primary-appearance"
                      style={{ transitionDelay: `${(timeout += 150)}ms` }}
                    >
                      {text}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {button ? (
            <div
              className="primary-appearance mt-10"
              style={{ transitionDelay: `${(timeout += 150)}ms` }}
            >
              <ACFButton {...button} />
            </div>
          ) : null}
        </div>
      </div>
      {image ? (
        <div className="w-full md:w-2/5 relative" style={{ minHeight: 200 }}>
          <Img
            className="absolute-fit"
            loading="eager"
            fluid={image.localFile.childImageSharp.fluid}
          />
        </div>
      ) : null}
    </section>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        image {
          id
          localFile {
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        list {
          text
        }
        button {
          type
          text
          link
          new_tab
        }
      }
    }
  }
`
